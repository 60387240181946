module.exports={
  "name": "aframe",
  "version": "0.8.0",
  "description": "A web framework for building virtual reality experiences.",
  "homepage": "https://aframe.io/",
  "main": "dist/aframe-master.js",
  "scripts": {
    "browserify": "browserify src/index.js -s 'AFRAME' -p browserify-derequire",
    "build": "shx mkdir -p build/ && npm run browserify -- --debug -t [envify --INSPECTOR_VERSION dev] -o build/aframe.js",
    "codecov": "codecov",
    "dev": "npm run build && cross-env INSPECTOR_VERSION=dev node ./scripts/budo -t envify",
    "dist": "node scripts/updateVersionLog.js && npm run dist:min && npm run dist:max",
    "dist:max": "npm run browserify -s -- --debug | exorcist dist/aframe.js.map > dist/aframe.js",
    "dist:min": "npm run browserify -s -- --debug -p [minifyify --map aframe.min.js.map --output dist/aframe.min.js.map] -o dist/aframe.min.js",
    "docs": "markserv --dir docs --port 9001",
    "preghpages": "node ./scripts/preghpages.js",
    "ghpages": "ghpages -p gh-pages/",
    "lint": "semistandard -v | snazzy",
    "lint:fix": "semistandard --fix",
    "precommit": "npm run lint",
    "prepush": "node scripts/testOnlyCheck.js",
    "prerelease": "node scripts/release.js 0.7.1 0.8.0",
    "start": "npm run dev",
    "test": "karma start ./tests/karma.conf.js",
    "test:docs": "node scripts/docsLint.js",
    "test:firefox": "npm test -- --browsers Firefox",
    "test:chrome": "npm test -- --browsers Chrome",
    "test:node": "mocha --ui tdd tests/node"
  },
  "repository": "aframevr/aframe",
  "license": "MIT",
  "dependencies": {
    "@tweenjs/tween.js": "^16.8.0",
    "browserify-css": "^0.8.2",
    "debug": "ngokevin/debug#noTimestamp",
    "deep-assign": "^2.0.0",
    "document-register-element": "dmarcos/document-register-element#8ccc532b7",
    "envify": "^3.4.1",
    "load-bmfont": "^1.2.3",
    "object-assign": "^4.0.1",
    "present": "0.0.6",
    "promise-polyfill": "^3.1.0",
    "style-attr": "^1.0.2",
    "three": "github:dmarcos/three.js#r90fixPose",
    "three-bmfont-text": "^2.1.0",
    "webvr-polyfill": "^0.9.40"
  },
  "devDependencies": {
    "browserify": "^13.1.0",
    "browserify-derequire": "^0.9.4",
    "browserify-istanbul": "^2.0.0",
    "budo": "^9.2.0",
    "chai": "^3.5.0",
    "chai-shallow-deep-equal": "^1.4.0",
    "chalk": "^1.1.3",
    "codecov": "^1.0.1",
    "cross-env": "^5.0.1",
    "exorcist": "^0.4.0",
    "ghpages": "0.0.8",
    "git-rev": "^0.2.1",
    "glob": "^7.1.1",
    "husky": "^0.11.7",
    "istanbul": "^0.4.5",
    "jsdom": "^9.11.0",
    "karma": "1.4.1",
    "karma-browserify": "^5.1.0",
    "karma-chai-shallow-deep-equal": "0.0.4",
    "karma-chrome-launcher": "^2.0.0",
    "karma-coverage": "^1.1.1",
    "karma-env-preprocessor": "^0.1.1",
    "karma-firefox-launcher": "^1.0.0",
    "karma-mocha": "^1.1.1",
    "karma-mocha-reporter": "^2.1.0",
    "karma-sinon-chai": "1.2.4",
    "lolex": "^1.5.1",
    "markserv": "github:sukima/markserv#feature/fix-broken-websoketio-link",
    "minifyify": "^7.3.3",
    "mocha": "^3.0.2",
    "mozilla-download": "^1.1.1",
    "replace-in-file": "^2.5.3",
    "semistandard": "^9.0.0",
    "shelljs": "^0.7.7",
    "shx": "^0.2.2",
    "sinon": "^1.17.5",
    "sinon-chai": "2.8.0",
    "snazzy": "^5.0.0",
    "too-wordy": "ngokevin/too-wordy",
    "uglifyjs": "^2.4.10",
    "write-good": "^0.9.1"
  },
  "link": true,
  "browserify": {
    "transform": [
      "browserify-css",
      "envify"
    ]
  },
  "semistandard": {
    "ignore": [
      "build/**",
      "dist/**",
      "examples/**/shaders/*.js",
      "**/vendor/**"
    ]
  },
  "keywords": [
    "3d",
    "aframe",
    "cardboard",
    "components",
    "oculus",
    "three",
    "three.js",
    "rift",
    "vive",
    "vr",
    "web-components",
    "webvr"
  ],
  "browserify-css": {
    "minify": true
  },
  "engines": {
    "node": ">= 4.6.0",
    "npm": "^2.15.9"
  }
}
