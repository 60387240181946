module.exports={
  "_args": [
    [
      "webvr-polyfill@0.9.41",
      "/Users/ngoke/Code/aframe"
    ]
  ],
  "_from": "webvr-polyfill@0.9.41",
  "_id": "webvr-polyfill@0.9.41",
  "_inBundle": false,
  "_integrity": "sha512-xgZPm7DXd2iUn4wh+/ubh1AzYWaHlx6VCmpxgTvoKzi1sMz9ePChQvsq1tm18aUfuzs6dtMrnNWoaQIwl81QsQ==",
  "_location": "/webvr-polyfill",
  "_phantomChildren": {},
  "_requested": {
    "type": "version",
    "registry": true,
    "raw": "webvr-polyfill@0.9.41",
    "name": "webvr-polyfill",
    "escapedName": "webvr-polyfill",
    "rawSpec": "0.9.41",
    "saveSpec": null,
    "fetchSpec": "0.9.41"
  },
  "_requiredBy": [
    "/"
  ],
  "_resolved": "https://registry.npmjs.org/webvr-polyfill/-/webvr-polyfill-0.9.41.tgz",
  "_spec": "0.9.41",
  "_where": "/Users/ngoke/Code/aframe",
  "authors": [
    "Boris Smus <boris@smus.com>",
    "Brandon Jones <tojiro@gmail.com>",
    "Jordan Santell <jordan@jsantell.com>"
  ],
  "bugs": {
    "url": "https://github.com/googlevr/webvr-polyfill/issues"
  },
  "description": "Use WebVR today, on mobile or desktop, without requiring a special browser build.",
  "devDependencies": {
    "chai": "^3.5.0",
    "jsdom": "^9.12.0",
    "mocha": "^3.2.0",
    "semver": "^5.3.0",
    "webpack": "^2.6.1",
    "webpack-dev-server": "2.7.1"
  },
  "homepage": "https://github.com/googlevr/webvr-polyfill",
  "keywords": [
    "vr",
    "webvr"
  ],
  "license": "Apache-2.0",
  "main": "src/node-entry",
  "name": "webvr-polyfill",
  "repository": {
    "type": "git",
    "url": "git+https://github.com/googlevr/webvr-polyfill.git"
  },
  "scripts": {
    "build": "webpack",
    "start": "npm run watch",
    "test": "mocha",
    "watch": "webpack-dev-server"
  },
  "version": "0.9.41"
}
